define('ember-cli-format-number/helpers/format-number', ['exports', 'ember', 'numeral'], function (exports, _ember, _numeral) {
  exports.formatNumber = formatNumber;

  function formatNumber(params, hash) {
    var _ref = hash || {};

    var format = _ref.format;

    var number = params;

    if (_ember['default'].isArray(params)) {
      number = params[0];
    }

    if (typeof number === 'undefined') {
      number = null;
    }

    if (isNaN(number)) {
      number = null;
    }

    return (0, _numeral['default'])(number).format(format);
  }

  exports['default'] = _ember['default'].Helper.helper(formatNumber);
});